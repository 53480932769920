import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { Power4 } from "gsap";
import { useAppState } from "@state/state";
import { useAnnouncementData } from "@staticQueries";
import { RichText, Container } from "@atoms";
import { Link, Button } from "@ui";
import { cookies } from "@utils";
import tl from "@timeline";

const { hasCookie } = cookies;

const generateId = (strings = []) =>
  strings.map(str => str.toLowerCase().replace(/ /g, "-")).join("-");

const AnnouncementBanner = () => {
  const [{ layout }, dispatch] = useAppState();
  const banner = useRef();
  const { copy, ctaText, ctaLink, isLive } = useAnnouncementData();

  // build an id based on the announcement copy and link url
  const announcementId = generateId([copy.raw[0].text, ctaLink.url]);

  // Set showingAnnouncement to true in app state if following checks pass
  // - no cookie exists for this announcementId
  // - announcement `isLive` field is set to true in the CMS
  useEffect(() => {
    if (!hasCookie("announcement", announcementId) && isLive) {
      dispatch({ type: "showAnnouncement", announcementId });
    }
  }, [isLive]);

  // Toggle the show/hide animation based on state
  useEffect(() => {
    if (!banner.current) {
      return;
    }

    if (layout.showingAnnouncement) {
      tl.to(banner.current, 1, {
        opacity: 1,
        height: banner.current.scrollHeight,
        ease: Power4.easeOut,
        onComplete: () => {
          if (banner.current) {
            banner.current.style.height = "auto";
          }
        },
      });
    } else {
      tl.to(banner.current, 1, { opacity: 0, height: 0, ease: Power4.easeOut });
    }
  }, [layout.showingAnnouncement]);

  if (!isLive) {
    return null;
  }

  return (
    <div
      ref={banner}
      className="overflow-hidden"
      style={{ height: 0, opacity: 0 }}
    >
      <div
        className={classNames({
          "p-4 pt-3 sm:pt-4 flex items-center bg-green-2": true,
          "pointer-events-auto": layout.showingAnnouncement,
          "pointer-events-none": !layout.showingAnnouncement,
        })}
      >
        <Container>
          <div className="flex items-center">
            <div className="flex flex-col sm:flex-row items-center">
              <RichText
                html={copy.html}
                className="RichText--banner text-white flex-1"
              />

              <div className="flex items-center pt-3 sm:pt-0">
                {ctaText && ctaLink.url && (
                  <Link
                    to={ctaLink.url}
                    target={ctaLink.target}
                    className="sm:mx-4 md:mx-6"
                  >
                    <Button
                      preset="secondary"
                      text={ctaText}
                      textOptions={{ style: "font-normal" }}
                      options={{
                        height: "h-8",
                      }}
                    />
                  </Link>
                )}

                <Button
                  preset="empty"
                  className="sm:hidden text-white hover:text-green-3 transition-fast ml-4"
                  animateIcon={false}
                  text="close"
                  textOptions={{ style: "font-normal" }}
                  onClick={() =>
                    dispatch({
                      type: "hideAnnouncement",
                      announcementId: layout.showingAnnouncement,
                    })
                  }
                />
              </div>
            </div>
            <Button
              iconName="X"
              preset="empty"
              className="hidden sm:block text-white hover:text-green-3 transition-fast ml-auto"
              animateIcon={false}
              onClick={() =>
                dispatch({
                  type: "hideAnnouncement",
                  announcementId: layout.showingAnnouncement,
                })
              }
            />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AnnouncementBanner;
