import React from "react";
import { Container, Text, Icon } from "@atoms";
import { Link } from "@ui";
import { LogoMarkLight } from "@svg";
import { useFooterData } from "@staticQueries";

const Footer = () => {
  const { menu, socialMenu, legal, address } = useFooterData();

  return (
    <footer className="Footer bg-green-1 py-16 md:py-24">
      <Container className="flex flex-col md:flex-row items-center md:items-start">
        {/* Logo Mark */}
        <div className="mb-12 md:mb-0 md:pr-12 lg:pr-20">
          <Link to="/" style={{ width: 73, height: 40 }} className="block">
            <LogoMarkLight />
          </Link>
        </div>

        {/* Main Menu */}
        <nav className="mb-12 md:mb-0 md:pr-12 lg:pr-20 md:mt-4 columns-2">
          {menu.map((item, idx) => (
            <Link
              key={idx}
              to={item.link.url}
              target={item.link.target}
              className="block text-white hover:text-green-3 mb-8 md:mb-10 transition-fast"
            >
              <Icon name="Angle" className="w-4 h-3 mr-3" />

              <Text
                tag="span"
                options={{
                  font: "font-mono",
                  size: "text-sm",
                  lineHeight: "leading-1.2",
                  letterSpacing: "tracking-md",
                }}
              >
                {item.text}
              </Text>
            </Link>
          ))}
        </nav>

        {/* Address/Legal/Social */}
        <div className="md:ml-auto text-center md:text-right">
          <Link
            to={`https://maps.google.com/?q=${encodeURIComponent(address)}`}
            target="_blank"
            className="block"
          >
            <Text
              tag="address"
              use="p"
              options={{ style: "not-italic", size: "text-2xs" }}
              className="text-white whitespace-pre-wrap mb-6 md:mb-10"
            >
              {address}
            </Text>
          </Link>

          <Link
            to={legal.link.url}
            target={legal.link.target}
            className="block text-white hover:text-green-3 mb-6 md:mb-10 transition-fast"
          >
            <Icon name="Angle" className="w-4 h-3 mr-3" />

            <Text
              tag="span"
              options={{
                font: "font-mono",
                size: "text-sm",
                lineHeight: "leading-1.2",
                letterSpacing: "tracking-md",
              }}
            >
              {legal.text}
            </Text>
          </Link>

          <div className="flex justify-center md:justify-end -mx-2">
            {socialMenu.map((item, idx) => (
              <Link
                key={idx}
                to={item.link.url}
                target={item.link.target}
                className="text-white hover:text-green-3 transition-fast"
              >
                <Icon name={item.account} className="w-4 h-4 mx-2" />
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
