import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  keysToCamel,
  resolveByPrefix,
} from "@dataResolvers";

const query = graphql`
  query FooterQuery {
    prismicGlobals {
      id
      data {
        footer_menu {
          link {
            url
            target
          }
          text
        }
        footer_social_menu {
          link {
            url
            target
          }
          account
        }
        footer_legal {
          link {
            url
            target
          }
          text
        }
        footer_address
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { prefixed } = resolveByPrefix(prismicGlobals.data, ["footer_"]);
  const { footer } = prefixed;

  return keysToCamel({
    ...footer,
    legal: footer.legal[0],
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
