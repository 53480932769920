import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const FadeIn = ({ children, className, duration }) => {
  const el = useRef();
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  return (
    <div
      ref={el}
      className={className}
      style={{
        opacity: hydrated ? 1 : 0,
        transition: `opacity ${duration}ms ease-in-out`,
      }}
    >
      {children}
    </div>
  );
};

FadeIn.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  className: PropTypes.string,
};

FadeIn.defaultProps = {
  duration: 1000,
  className: "",
};

export default FadeIn;
