import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Section is used to separate content vertically, often
// with a different background color

const Section = ({
  hasPaddingTop,
  hasPaddingBottom,
  size,
  allowOverflow,
  className: _className,
  children,
  style,
}) => {
  // if we passed a custom `className` string then use that,
  // else build it based on other props
  const className = classNames(
    {
      relative: true,
      "overflow-hidden": !allowOverflow,
      "pt-10 sm:pt-12 md:pt-24": hasPaddingTop && size === "lg",
      "pb-10 sm:pb-12 md:pb-24": hasPaddingBottom && size === "lg",
      "pt-16 sm:pt-24 md:pt-32": hasPaddingTop && size === "xl",
      "pb-16 sm:pb-24 md:pb-32": hasPaddingBottom && size === "xl",
      "pt-6 sm:pt-10": hasPaddingTop && size === "sm",
      "pb-6 sm:pb-10": hasPaddingBottom && size === "sm",
    },
    _className
  );

  return (
    <section className={className} style={style}>
      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  hasPaddingTop: PropTypes.bool,
  hasPaddingBottom: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg", "xl"]),
  allowOverflow: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Section.defaultProps = {
  hasPaddingTop: true,
  hasPaddingBottom: true,
  size: "lg",
  allowOverflow: false,
  className: "",
  style: {},
};

export default Section;
