import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * `Triangle` SVG right-angle triangle
 */

const Triangle = ({ orientation, className }) => {
  const points = {
    "top-left": "0,0 100,0 0,100",
    "top-right": "0,0 100,0 100,100",
    "bottom-left": "0,0 0,100 100,100",
    "bottom-right": "100,0 100,100 0,100",
  };

  return (
    <div
      className={classNames(
        {
          "absolute bottom-0 top-0 w-full sm:w-1/3 md:w-1/2 pointer-events-none": true,
          "right-0": orientation.split("-")[1] === "right",
          "left-0": orientation.split("-")[1] === "left",
        },
        className
      )}
    >
      <svg
        className="w-full h-full"
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
        fill="currentColor"
      >
        <polygon points={points[orientation]} />
      </svg>
    </div>
  );
};

Triangle.propTypes = {
  orientation: PropTypes.oneOf([
    "top-left",
    "top-right",
    "bottom-left",
    "bottom-right",
  ]),
  className: PropTypes.string,
};

Triangle.defaultProps = {
  orientation: "bottom-right",
  className: "",
};

export default Triangle;
