import { createAppState } from "@designbycosmic/cosmic-react-state-management";
import layout from "./reducers/layout";
import breakpoints from "./reducers/breakpoints";
import contactPage from "./reducers/contactPage";
import teamPage from "./reducers/teamPage";
import philosophyPage from "./reducers/philosophyPage";

const reducer = {
  layout: layout.reducer,
  breakpoints: breakpoints.reducer,
  contactPage: contactPage.reducer,
  teamPage: teamPage.reducer,
  philosophyPage: philosophyPage.reducer,
};

const initialState = {
  layout: layout.initialState,
  breakpoints: breakpoints.initialState,
  contactPage: contactPage.initialState,
  teamPage: teamPage.initialState,
  philosophyPage: philosophyPage.initialState,
};

export const {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
} = createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
