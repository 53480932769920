import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { imageProps } from "./Image";

// Low Quality Image Placeholder

const ImageLQIP = ({ image, objectFit }) => {
  const { url, fx, fy, filter } = image;

  let src = url;
  src += "?w=50&h=50";
  if (filter) {
    const filterStr = encodeURIComponent(`${filter},FFFFFF`);
    src += `&duotone=${filterStr}&duotone-alpha=100`;
  }

  const positionX = fx ? fx.toLowerCase() : "center";
  const positionY = fy ? fy.toLowerCase() : "center";

  const lqipClassName = classNames({
    "absolute inset-0 z-10 -m-8 bg-cover transition-opacity transition-750": true,
  });

  return (
    <div
      style={{
        backgroundImage: `url(${src})`,
        filter: filter ? "none" : "blur(25px)",
        backgroundSize: objectFit,
        backgroundPosition: `${positionX} ${positionY}`,
      }}
      className={lqipClassName}
    />
  );
};

ImageLQIP.propTypes = {
  image: imageProps.isRequired,
  objectFit: PropTypes.string.isRequired,
};

export default ImageLQIP;
