import React from "react";
import PropTypes from "prop-types";
import Input, { inputPropTypes } from "./Input";
import FileSelector from "./FileSelector";

/**
 *
 * `StyledInput` A styled component that wraps Input
 *
 */

const StyledInput = ({ className, type, ...rest }) => {
  if (type === "file") {
    return <FileSelector {...rest} className={className} />;
  }

  return (
    <Input
      className={`StyledInput text-gray bg-gray-4 border w-full h-10 font-sans text-sm px-4 transition-fast rounded-none ${className}`}
      focusedClassName="border border-green-3"
      unFocusedClassName="border-transparent"
      type={type}
      {...rest}
    />
  );
};

StyledInput.propTypes = {
  className: PropTypes.string,
  ...inputPropTypes,
};

StyledInput.defaultProps = {
  className: "",
};

export default StyledInput;
