import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Imgix from "react-imgix";
import qs from "query-string";
import classNames from "classnames";
import { mapFocalAreaToDecimal, onImageLoad } from "@utils";

// Image - use Imgix to render optimized images, including low-res placeholders

const Image = ({
  image,
  ixParams,
  objectFit,
  disableSrcSet,
  width,
  height,
  disableDrag,
}) => {
  const [loaded, setLoaded] = useState();

  const { url: _url, alt, fpx, fpy, filter, watermark } = image;

  // Prismic sends us urls with imigix query params baked in
  // so first getting the raw url with no query params.
  const url = _url.split("?")[0];

  // However, we do want to get the `rect` query param since
  // this holds the crop value that users may have generated
  // using the Prismic image editor. We use this when setting
  // defaultParams below
  const { rect } = qs.parse(_url);

  // If any of the image props changed, then we set loading to false
  // so we can fade it back in when the new image is ready
  useEffect(() => {
    if (loaded) {
      setLoaded(false);
    }
  }, [url, width, height]);

  // this callback is passed to the Imgix component and runs
  // when the img element is loaded into the DOM. From there we
  // pass the img to onImageLoad
  const handleRef = img => {
    if (!img) {
      return;
    }

    if (!loaded) {
      onImageLoad(img, () => {
        // extra setTimeout for DOM rendering
        setTimeout(() => {
          setLoaded(true);
        }, 50);
      });
    }

    if (disableDrag) {
      img.addEventListener("dragstart", e => e.preventDefault());
    }
  };

  const defaultParams = {
    auto: encodeURIComponent("compress,format"),
    // fm: "jp2",
    fit: "crop",
  };

  // set crop based on passed focal point values
  // if no focal point values, then use reasonable default
  if (fpx || fpy) {
    defaultParams["fp-x"] = mapFocalAreaToDecimal(fpx);
    defaultParams["fp-y"] = mapFocalAreaToDecimal(fpy);
  }
  if (rect) {
    defaultParams.rect = rect;
  }
  if (!fpx && !fpy && !rect) {
    defaultParams.crop = encodeURIComponent("faces,entropy,edges");
  }

  // add saturation filter if included with image props
  if (filter) {
    defaultParams.sat = "-30";
  }

  // add watermark if included with image props
  if (watermark) {
    // TODO: should be able to pass props about size and alignment
    defaultParams.mark = watermark;
    defaultParams["mark-w"] = "143";
    defaultParams["mark-h"] = "126";
    defaultParams["mark-align"] = "center,middle";
  }

  const style = {};
  if (objectFit === "cover") {
    const posX = mapFocalAreaToDecimal(fpx) * 100;
    const posY = mapFocalAreaToDecimal(fpy) * 100;
    style.objectPosition = `${posX}% ${posY}%`;
  }

  if (width && height) {
    return (
      <Imgix
        src={url.replace(/\+/g, " ")}
        imgixParams={{
          ...defaultParams,
          ...ixParams,
        }}
        width={width}
        height={height}
        alt={alt}
        htmlAttributes={{ ref: handleRef, style }}
        disableSrcSet={disableSrcSet}
        className={classNames({
          "relative z-20 transition": true,
          [`object-${objectFit}`]: true,
          "w-full h-full": objectFit !== "contain",
          "opacity-0": !loaded,
          "opacity-100": loaded,
        })}
        disableLibraryParam
      />
    );
  }

  return null;
};

export const imageProps = PropTypes.shape({
  url: PropTypes.string.isRequired,
  alt: PropTypes.string,
  fpx: PropTypes.string,
  fpy: PropTypes.string,
  filter: PropTypes.bool,
  watermark: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  caption: PropTypes.shape({
    html: PropTypes.string,
  }),
});

Image.propTypes = {
  image: imageProps.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  ixParams: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  objectFit: PropTypes.oneOf(["cover", "contain"]),
  disableSrcSet: PropTypes.bool,
  disableDrag: PropTypes.bool,
};

Image.defaultProps = {
  width: null,
  height: null,
  ixParams: {},
  objectFit: "cover",
  disableSrcSet: false,
  disableDrag: false,
};

export default Image;
