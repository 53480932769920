import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  keysToCamel,
  resolveByPrefix,
} from "@dataResolvers";

const query = graphql`
  query AnnouncementQuery {
    prismicGlobals {
      id
      data {
        announcement_copy {
          html
          raw
        }
        announcement_cta_text
        announcement_cta_link {
          url
          target
        }
        announcement_is_live
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { prefixed } = resolveByPrefix(prismicGlobals.data, ["announcement_"]);
  const { announcement } = prefixed;
  return keysToCamel({
    ...announcement,
    isLive: announcement.is_live === "Yes",
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
