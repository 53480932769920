import React, { useEffect } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Modal } from "@designbycosmic/cosmic-react-common-components";
import useScrollPosition from "@designbycosmic/cosmic-react-scroll-hook";
import { Text } from "@atoms";
import { Link } from "@ui";
import { useFooterData } from "@staticQueries";
import { fadeIn, fadeOut } from "@timeline";
import { getTailwindUtility } from "@utils";

const hasWindow = typeof window !== "undefined";
const { green, gray } = getTailwindUtility("colors");

const MobileMenu = () => {
  const { menu } = useFooterData();
  const [{ layout }, dispatch] = useAppState();
  const { scrollY } = hasWindow ? useScrollPosition() : { scrollY: 0 };

  useEffect(() => {
    if (layout.showingMobileNav) {
      dispatch({ type: "toggleMobileNav" });
    }
  }, [scrollY]);

  return (
    <Modal
      showing={layout.showingMobileNav}
      setShowing={() => dispatch({ type: "toggleMobileNav" })}
      showingAnimation={fadeIn}
      hidingAnimation={fadeOut}
      style={{
        backgroundColor: layout.theme === "dark" ? green[1] : gray[5],
        opacity: 0,
      }}
      fullScreen
      className="z-40"
      ModalClose={() => null}
    >
      <nav className="flex flex-col items-center justify-center w-full h-full px-6 py-12">
        <div className="flex justify-center">
          <Link
            to="/"
            className={classNames({
              " py-4 hover:text-turq transition-fast": true,
              "text-white hover:text-turq": layout.theme === "dark",
              "text-gray hover:text-turq": layout.theme === "light",
            })}
          >
            <Text tag="span" use="button" options={{ size: "text-mdLg" }}>
              Home
            </Text>
          </Link>
        </div>

        {menu.map((item, idx) => (
          <div key={idx} className="flex justify-center">
            <Link
              to={item.link.url}
              target={item.link.target}
              className={classNames({
                " py-4 hover:text-turq transition-fast": true,
                "text-white hover:text-turq": layout.theme === "dark",
                "text-gray hover:text-turq": layout.theme === "light",
              })}
            >
              <Text tag="span" use="button" options={{ size: "text-mdLg" }}>
                {item.text}
              </Text>
            </Link>
          </div>
        ))}
      </nav>
    </Modal>
  );
};

export default MobileMenu;
