/**
 *
 * This is a shortcut for using the basic page transition. It will generally serve well
 * across the app for intra-site links there may be a case where a link-specific animation
 * is neccesary, in that case, just use TransitionLink
 *
 * @param {string} to link to a page on the site, starting with /
 *
 */

import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink, navigate } from "gatsby";
import { useAppState } from "@state/state";

const Link = ({ to, target, className, style, children }) => {
  const [, dispatch] = useAppState();

  const handleClick = event => {
    // Abort if user is opening link in a new tab via cmd-click or ctrl-click
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    // Take over link control
    event.preventDefault();

    // Do nothing if navigating to page we're already on
    if (to === window.location.pathname) {
      return;
    }

    // Trigger page transition
    dispatch({
      type: "setTransition",
      doingTransition: to,
    });

    // Execute navigation after timeout for page transition outro
    setTimeout(() => {
      navigate(to);
    }, 500);
  };

  if (target === "_blank") {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        style={style}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      className={className}
      style={style}
      to={to}
      onClick={handleClick}
    >
      {children}
    </GatsbyLink>
  );
};

Link.defaultProps = {
  className: "",
  style: {},
  target: undefined,
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  target: PropTypes.string,
};

export default Link;
