/**
 * `linkResolver` is a function that maps Prismic documents to urls on the website.
 * In many cases, you can manually build a url directly in a component, but in some
 * cases (e.g., links rendered by a RichText field), links need to be built dynamically.
 *
 */

const linkResolver = (doc = {}) => {
  // As far as we can tell, sometimes linkResolver needs to return a path,
  // and sometimes it needs to return a function that returns a path 🤷‍♂️

  // TODO: extend linkResolver to receive document objects that already have a url

  if (!doc.type) {
    return linkResolver;
  }

  const linkMap = {
    home_page: "/",
    contact_page: "/contact/",
    team_page: "/team/",
    portfolio_page: "/portfolio/",
    philosophy_page: "/philosophy/",
    general_pages: `/${doc.uid}/`,
    not_found_page: `/404/`,
  };

  return linkMap[doc.type] || "/";
};

module.exports = linkResolver;
