import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import useWindowSize from "@designbycosmic/cosmic-react-resize-hook";
import Image, { imageProps } from "./Image";
import ImageLQIP from "./ImageLQIP";
import RichText from "../RichText";

// Image - use Imgix to render optimized images, including low-res placeholders
const maxDimension = 2000;

const ImageWrap = ({
  image,
  ixParams,
  showLQIP,
  objectFit,
  disableSrcSet,
  forceHeight,
  disableDrag,
  className: _className,
}) => {
  if (!image || !image.url) {
    return null;
  }

  // set state var for saving the width/height of the container
  const [dimensions, setDimensions] = useState({});
  const el = useRef({});

  const doSetDimensions = () => {
    // Start by applying a width equal to the container's width
    const { top, bottom, left, right } = el.current.getBoundingClientRect();
    let width = el.clientWidth || right - left;
    const ratio = image.width / image.height;
    let height;

    // If we want to preserve the uploaded image's dimensions
    // then calculate the height based on those dimensions
    if (objectFit === "contain") {
      height = width / ratio;
    } else {
      // Otherwise we set the height equal to the container height.
      // Note: this is typical when we need something akin to a
      // background image.
      height = el.current.clientHeight || bottom - top;
    }

    // If we're forcing a particular height, then recalculate based on
    // whatever height was passed in via `forceHeight`
    if (forceHeight) {
      height = forceHeight;
      width = height * ratio;
    }

    // Resize again if the dimensions are greater than our pre-defined max
    if (width > maxDimension) {
      width = maxDimension;
      height = maxDimension / ratio;
    }
    if (height > maxDimension) {
      height = maxDimension;
      width = maxDimension * ratio;
    }

    // Set the final calculation to state
    setDimensions({
      width,
      height,
    });
  };

  // Get current window size from hook, and use `useEffect` to
  // calculate new dimensions if the windowSize changes.
  // Also get new dimensions if any of the image props change.
  const { innerWidth } = useWindowSize();
  useEffect(() => {
    doSetDimensions();
  }, [...Object.values(image), innerWidth, forceHeight]);

  const { caption } = image;

  return (
    <figure>
      <div
        ref={el}
        className={classNames(
          {
            "overflow-hidden": true,
            relative: objectFit === "contain",
            "absolute inset-0": objectFit !== "contain",
          },
          _className
        )}
        style={{
          height: objectFit === "contain" ? `${dimensions.height}px` : "auto",
        }}
      >
        <Image
          image={image}
          ixParams={ixParams}
          width={dimensions.width}
          height={dimensions.height}
          objectFit={objectFit}
          disableSrcSet={disableSrcSet}
          disableDrag={disableDrag}
        />
        {showLQIP && <ImageLQIP image={image} objectFit={objectFit} />}
      </div>

      {/* 
        Note: captions *should* only be used with objectFit `contain`
        since `cover` is typically used for bg image scenarios.
      */}
      {caption && objectFit === "contain" && (
        <figcaption className="pt-3">
          <RichText html={caption.html} className="italic text-xs text-gray" />
        </figcaption>
      )}
    </figure>
  );
};

ImageWrap.propTypes = {
  image: imageProps.isRequired,
  ixParams: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  showLQIP: PropTypes.bool,
  objectFit: PropTypes.oneOf(["cover", "contain"]),
  disableSrcSet: PropTypes.bool,
  forceHeight: PropTypes.number,
  disableDrag: PropTypes.bool,
  className: PropTypes.string,
};

ImageWrap.defaultProps = {
  ixParams: {},
  showLQIP: true,
  objectFit: "cover",
  disableSrcSet: false,
  forceHeight: null,
  disableDrag: false,
  className: "",
};

export default ImageWrap;
