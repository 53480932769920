import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  resolveImage,
  resolveByPrefix,
  resolveExtraGroups,
  keysToCamel,
} from "@dataResolvers";

const query = graphql`
  query NotFoundPageQuery {
    prismicNotFoundPage {
      id
      data {
        hero {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
        }
        prefooter {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        meta_title
        meta_description
        meta_image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicNotFoundPage } = response;
  const { prefixed, unPrefixed } = resolveByPrefix(prismicNotFoundPage.data, [
    "meta_",
  ]);
  const { meta } = prefixed;

  const unGrouped = resolveExtraGroups(unPrefixed);

  return keysToCamel({
    ...unGrouped,
    meta: {
      ...meta,
      image: resolveImage(meta.image),
    },
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
