import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  resolveSlices,
  keysToCamel,
} from "@dataResolvers";

const query = graphql`
  query ContactFormQuery {
    prismicContactPage {
      id
      data {
        form_wrapper {
          heading {
            raw
          }
          description {
            html
          }
          cta_text
        }
        form_messages {
          success_heading
          success_message {
            html
          }
          error_heading
          error_message {
            html
          }
        }
        body {
          ... on PrismicContactPageBodyInput {
            id
            slice_type
            primary {
              type
              label
            }
          }
          ... on PrismicContactPageBodyPersonSelector {
            id
            slice_type
            primary {
              label
            }
            items {
              person {
                uid
                document {
                  ... on PrismicPeople {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
          }
          ... on PrismicContactPageBodyTextarea {
            id
            slice_type
            primary {
              label
            }
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicContactPage } = response;
  const { body, form_wrapper, form_messages } = prismicContactPage.data;

  return keysToCamel({
    wrapper: form_wrapper[0],
    messages: form_messages[0],
    form: resolveSlices(body),
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
