import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { TimelineLite, Power2 } from "gsap";
import { Text } from "@atoms";
import { ThemeSetter } from "@ui";
import Footer from "./Footer";
import SEO, { seoProps } from "./SEO";

const DefaultLayout = ({ children, meta, theme, ...rest }) => {
  const [{ layout }, dispatch] = useAppState();
  const el = useRef();
  const [pageview, setPageview] = useState(false);

  const transitionTl = new TimelineLite();

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("popstate", function(e) {
        dispatch({
          type: "setTransition",
          doingTransition: null,
        });
      });
    }
  }, []);

  useEffect(() => {
    if (layout.doingTransition && layout.doingTransition !== rest.path) {
      transitionTl.to(el.current, 0.5, {
        opacity: 0,
        y: 30,
        ease: Power2.easeInOut,
      });
    } else if (
      !layout.doingTransition ||
      layout.doingTransition === rest.path
    ) {
      if (layout.showingMobileNav) {
        dispatch({ type: "toggleMobileNav" });
      }

      dispatch({
        type: "setTransition",
        doingTransition: null,
      });

      transitionTl
        .to(el.current, 0, {
          y: 0,
        })
        .to(el.current, 0.67, {
          opacity: 1,
          ease: Power2.easeIn,
          onComplete: () => {
            // avoid css transform/fixed position issues
            if (!pageview && typeof window !== "undefined") {
              const dataLayer = window.dataLayer || [];
              dataLayer.push({ event: "routeChange" });
              setPageview(true);
            }
            if (el.current) {
              el.current.style.transform = "none";
            }
          },
        });
    }
  }, [layout.doingTransition, rest.path]);

  return (
    <>
      <ThemeSetter theme={theme} />
      <SEO {...meta} />

      <div
        className={classNames({
          "DefaultLayout transition": true,
          "pb-12": layout.previewMode,
          "bg-green-1": layout.theme === "dark",
          "bg-gray-5": layout.theme === "light",
        })}
      >
        <div ref={el} style={{ opacity: 0 }}>
          {children}
          <Footer />
        </div>

        {layout.previewMode && (
          <div
            className="fixed h-12 bg-green-3 flex items-center px-6 z-50"
            style={{ bottom: 0, left: 0, right: 0 }}
          >
            <Text
              tag="label"
              options={{ size: "text-sm" }}
              className="text-white"
            >
              You are viewing a content preview
            </Text>
          </div>
        )}
      </div>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  meta: PropTypes.shape(seoProps).isRequired,
  theme: PropTypes.string,
};

DefaultLayout.defaultProps = {
  theme: "dark",
};

export default DefaultLayout;
