import { registerMiddleware } from "@designbycosmic/cosmic-react-state-management";
import { cookies } from "@utils";

const { setCookie } = cookies;

const reducer = (state, action) => {
  switch (action.type) {
    case "hideAnnouncement":
      return {
        ...state,
        showingAnnouncement: null,
      };
    case "showAnnouncement":
      return {
        ...state,
        showingAnnouncement: action.announcementId,
      };
    case "setPreviewMode":
      return {
        ...state,
        previewMode: action.previewMode,
      };
    case "toggleMobileNav":
      return {
        ...state,
        showingMobileNav: !state.showingMobileNav,
      };
    case "setTheme":
      return {
        ...state,
        theme: action.theme,
      };
    case "setTransition":
      return {
        ...state,
        doingTransition: action.doingTransition,
      };
    case "setIsTouchDevice":
      return {
        ...state,
        isTouchDevice: action.isTouchDevice,
      };

    default:
      return state;
  }
};

registerMiddleware({
  actionType: "hideAnnouncement",
  func: ({ announcementId }) => {
    // When calling `hideAnnouncement` we need to pass the announcementId
    // in the action param
    setCookie("announcement", announcementId, 7);
  },
});

const initialState = {
  showingAnnouncement: false,
  previewMode: false,
  showingMobileNav: false,
  theme: "dark",
  doingTransition: false,
  isTouchDevice: false,
};

export default {
  reducer,
  initialState,
};
