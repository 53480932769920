/**
 * `resolveEmbeddedDocuments` is used to remove extraneous object nesting from embedded documents.
 * This is typically used on Prismic content relationship fields.
 *
 * @param {object[]} docs The array of embedded documents
 *
 */

const resolveEmbeddedDocuments = docs => {
  if (Array.isArray(docs)) {
    // first filter out any empty docs
    const nonEmpty = docs.filter(item => {
      const firstKey = Object.keys(item)[0];
      return item[firstKey].document;
    });

    // then map them recursively
    return nonEmpty.map(item => {
      const firstKey = Object.keys(item)[0];
      return resolveEmbeddedDocuments(item[firstKey]);
    });
  }

  if (!docs.document) {
    return null;
  }

  const { id, uid, type, data } = docs.document || {};
  return {
    id,
    uid,
    type,
    ...data,
  };
};

export default resolveEmbeddedDocuments;
