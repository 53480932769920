import React, { useRef } from "react";
import PropTypes from "prop-types";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import { TimelineLite, Expo } from "gsap";

const SectionBreak = ({ delay, className }) => {
  const el = useRef();

  const onEnter = () => {
    if (!el.current) {
      return;
    }

    const tl = new TimelineLite();

    setTimeout(() => {
      tl.fromTo(
        el.current.querySelectorAll("line")[0],
        1.25,
        {
          opacity: 0,
          x: "-100%",
          ease: Expo.easeOut,
        },
        {
          opacity: 0.35,
          x: 0,
          ease: Expo.easeOut,
        }
      );
    }, delay);
  };

  return (
    <InView
      onEnter={onEnter}
      observerOptions={{
        threshold: 0.99,
        rootMargin: "-25% 0% -25% 0%",
      }}
      unobserveAfterEntry
    >
      <div ref={el} className={`SectionBreak overflow-hidden ${className}`}>
        <svg width="100%" height={3} strokeWidth={3}>
          <line
            x1={0}
            x2="100%"
            y1="100%"
            y2="100%"
            stroke="currentColor"
            style={{ opacity: 0 }}
            strokeDasharray="3, 8"
          />
        </svg>
      </div>
    </InView>
  );
};

SectionBreak.propTypes = {
  delay: PropTypes.number,
  className: PropTypes.string,
};

SectionBreak.defaultProps = {
  delay: 0,
  className: "",
};

export default React.memo(SectionBreak);
