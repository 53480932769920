/**
 * `keysToCamel` converts the snake_cased Prismic field names to camelCase.
 * This is typically used after all other dataResolvers to avoid conflicts.
 *
 * Adapted from: https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascript
 *
 */

export const toCamel = (str, stripTrailingDigit) => {
  let s = str;

  const trailingDigit = stripTrailingDigit && s.match(/(\d+)$/);

  if (trailingDigit) {
    const len = trailingDigit[0].length;
    s = s.substring(0, s.length - len);
  }

  return s.replace(/([-_][a-z0-9])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace("-", "")
      .replace("_", "");
  });
};

const isArray = a => {
  return Array.isArray(a);
};

const isObject = o => {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

const keysToCamel = (o, options = {}) => {
  const { stripTrailingDigit = false } = options;

  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      // Prismic preview links for documents currently have circular references,
      // which causes a stack overflow error during the keysToCamel recursive loop.
      // This fix checks to see if the object is a document link field, and if so it
      // ignores the `document` property, which is where the circular reference lives.
      if (o.link_type === "Document" && k === "document") {
        return;
      }
      n[toCamel(k, stripTrailingDigit)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map(i => {
      return keysToCamel(i);
    });
  }

  return o;
};

export default keysToCamel;
