import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  resolveImage,
  resolveByPrefix,
  resolveExtraGroups,
  keysToCamel,
  resolveEmbeddedDocuments,
} from "@dataResolvers";

const query = graphql`
  query PhilosophyPageQuery {
    prismicPhilosophyPage {
      id
      data {
        hero {
          heading {
            raw
          }
        }
        overview {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        testimonials {
          quote {
            html
          }
          person
          title
          photo {
            url
            alt
            dimensions {
              width
              height
            }
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        disruptions_heading {
          raw
        }
        disruptions_subheading
        disruptions_items {
          item {
            html
          }
        }
        companies_heading {
          raw
        }
        companies_subheading
        companies_cta_text
        companies_cta_link {
          url
          target
        }
        companies_items {
          company {
            document {
              ... on PrismicCompanies {
                id
                data {
                  name
                  description {
                    html
                  }
                  status
                  status_subtitle
                  exited
                  color_logo {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        prefooter {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        meta_title
        meta_description
        meta_image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicPhilosophyPage } = response;
  const { prefixed, unPrefixed } = resolveByPrefix(prismicPhilosophyPage.data, [
    "disruptions_",
    "companies_",
    "meta_",
  ]);
  const { disruptions, companies, meta } = prefixed;

  const unGrouped = resolveExtraGroups(unPrefixed);

  return keysToCamel({
    ...unGrouped,
    disruptions,
    companies: {
      ...companies,
      companies: companies.items.map(item => ({
        ...resolveEmbeddedDocuments(item.company),
        logo: resolveImage(resolveEmbeddedDocuments(item.company).color_logo),
      })),
    },
    testimonials: unPrefixed.testimonials.map(item => ({
      ...item,
      photo: resolveImage(item.photo),
      image: resolveImage(item.image),
    })),
    meta: {
      ...meta,
      image: resolveImage(meta.image),
    },
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
