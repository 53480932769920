import resolveImage from "./resolveImage";

/**
 * `resolveExtraGroups` can be used to remove extraneous array wrappers from Prismic groups.
 *
 * @param {object} objectOfGroups An object with key/vals that may have extra array groups
 *
 * !!! WARNING: this should only be used on Prismic groups with `repeat: false` !!!
 */

const parseItem = item => {
  if (!item || typeof item !== "object" || Array.isArray(item)) {
    return item;
  }

  return Object.entries(item).reduce((obj, cur) => {
    const [key, val] = cur;

    let newVal = val;

    // Check if we have any embedded images and resolve those too.
    // Note: image field must be named "image"
    if (key === "image") {
      newVal = resolveImage(val);
    }

    return {
      ...obj,
      [key]: newVal,
    };
  }, {});
};

const resolveExtraGroups = objectOfGroups => {
  return Object.entries(objectOfGroups).reduce((obj, cur) => {
    const [key, val] = cur;

    // if any vals are arrays with a single item, then return the item
    // instead of the array
    const newVal = Array.isArray(val) && val.length === 1 ? val[0] : val;

    return {
      ...obj,
      [key]: parseItem(newVal),
    };
  }, {});
};

export default resolveExtraGroups;
