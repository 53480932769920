/**
 * `resolveByPrefix` is used to group related fields together based on field name prefixes.
 *
 * @param {object} obj The data with prefixed field names
 * @param {string|string[]} match The prefixes to group fields by
 * @param {string} [separator = "_"] The character that delineates the end of the prefix, defaults to
 *
 * Imagine data formatted like:
 * ```
 * {
 *   hero_image,
 *   hero_heading,
 *   hero_description,
 *   video_heading
 *   video_description
 *   video_url
 *   other_field
 *   ...rest
 * }
 * ```
 *
 * You can group these fields together like so:
 * ```
 * const { prefixed, unPrefixed } = resolveByPrefix(data, ["hero_", "video_"]);
 * ```
 *
 * this returns two objects `prefixed` and `unPrefixed` with the following shape
 * ```
 * //prefixed
 * {
 *   hero: {
 *     image,
 *     heading,
 *     description,
 *   }
 *   video: {
 *     heading,
 *     description,
 *     url,
 *   }
 * }
 *
 * // unPrefixed
 * {
 *   ...rest
 * }
 * ```
 */

const mapArrayToObject = (keys, obj, prefix) => {
  return keys.reduce((acc, cur) => {
    let key = cur;
    if (prefix) {
      key = cur.replace(prefix, "");
    }
    acc[key] = obj[cur];
    return acc;
  }, {});
};

const getPrefixed = (obj, prefix) => {
  const len = prefix.length;
  const keys = Object.keys(obj).filter(key => {
    return key.substring(0, len) === prefix;
  });

  return mapArrayToObject(keys, obj, prefix);
};

const getUnPrefixed = (obj, prefixes) => {
  const keys = Object.keys(obj).filter(key => {
    return prefixes.every(item => {
      const len = item.length;
      return key.substring(0, len) !== item;
    });
  });

  return mapArrayToObject(keys, obj);
};

const resolveByPrefix = (obj, match) => {
  const prefixes = Array.isArray(match) ? match : [match];

  const prefixed = prefixes.reduce((acc, cur) => {
    // replace last instance of underscore
    const key = cur.replace(new RegExp("_$"), "");
    acc[key] = getPrefixed(obj, cur);
    return acc;
  }, {});

  const unPrefixed = getUnPrefixed(obj, prefixes);

  return { prefixed, unPrefixed };
};

export default resolveByPrefix;
