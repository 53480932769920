import React from "react";
import TextRaw, { TextRawProps } from "./TextRaw";

// Pass default options for common text elements accross the various
// style attributes: font, size, style (bold/italic), line-height, letter-spacing

const config = {
  typography: {
    base: {},
    h1: {
      font: "font-sans",
      size: "text-2xl sm:text-3xl",
      style: "font-bold",
      lineHeight: "leading-1.4",
      letterSpacing: "tracking-sm",
    },
    h2: {
      font: "font-sans",
      size: "text-xl sm:text-2xl",
      style: "font-bold",
      lineHeight: "leading-1.4",
      letterSpacing: "tracking-sm",
    },
    h3: {
      font: "font-sans",
      size: "text-lg sm:text-xl",
      style: "font-bold",
      lineHeight: "leading-1.4",
      letterSpacing: "tracking-sm",
    },
    p: {
      font: "font-sans",
      size: "text-base",
      style: "font-normal",
      lineHeight: "leading-1.9",
      letterSpacing: "tracking-sm",
    },
    label: {
      font: "font-mono",
      size: "text-lg",
      style: "font-bold uppercase",
      lineHeight: "leading-1.6",
      letterSpacing: "tracking-lg",
    },
    button: {
      font: "font-mono",
      size: "text-sm",
      style: "font-bold",
      lineHeight: "leading-none",
      letterSpacing: "tracking-lg",
    },
  },
};

const Text = props => <TextRaw {...props} config={config} />;

const { config: textRawConfig, ...textRawPropsMinusConfig } = TextRawProps;

Text.propTypes = textRawPropsMinusConfig;
Text.defaultProps = TextRaw.defaultProps;

export default Text;
