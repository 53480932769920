/* eslint-disable import/prefer-default-export */
/* eslint react/prop-types: 0 */

import React from "react";
import "./src/pcss/index.pcss";
import wrapWithState from "@state/wrapWithState";
import { gsap } from "gsap";
import {
  AnnouncementBanner,
  LayoutUpdater,
  Header,
  MobileMenu,
  GTMLoader,
} from "@layout";
import { DrawSVGPlugin } from "./src/utils/DrawSVGPlugin";

// register extra GSAP plugins
gsap.registerPlugin(DrawSVGPlugin);

export const wrapRootElement = wrapWithState;

export const wrapPageElement = ({ element, props }) => {
  const { location } = props;

  return (
    <>
      <GTMLoader />
      <LayoutUpdater />
      <AnnouncementBanner />
      <Header currentPath={location.pathname} />
      <MobileMenu />
      {element}
    </>
  );
};
