import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import classNames from "classnames";
import { Text, RichText, RawText } from "@atoms";
import { useContactFormData } from "@staticQueries";
import { encodeForm } from "@utils";
import Button from "./StyledButton";

/**
 *
 * `ContactForm` Netlify form submitter for contact fields
 *
 */

const ContactForm = ({ className, onCloseMessage, initialContact }) => {
  const { wrapper, messages, form } = useContactFormData();

  const initialFormData = {
    ...form.reduce(
      (formData, cur) => {
        return {
          ...formData,
          [cur.name]: cur.options ? initialContact : "",
        };
      },
      { Information: "" } // `Information` is our honeypot field
    ),
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [showingMessage, setShowingMessage] = useState(false);

  const clearForm = () => setFormData(initialFormData);

  const handleSuccess = () => {
    clearForm();
    setLoading(false);
    setResult("success");
    setShowingMessage(true);
  };

  const handleError = err => {
    // eslint-disable-next-line
    console.log(err);
    setLoading(false);
    setResult("error");
    setShowingMessage(true);
  };

  const handleSubmit = event => {
    event.preventDefault();

    setResult(null);
    setLoading(true);

    const body = encodeForm({
      "form-name": "Contact Form",
      ...formData,
    });

    axios
      .post("/", body)
      .then(handleSuccess)
      .catch(handleError);
  };

  const closeMessage = () => {
    setShowingMessage(false);
    if (onCloseMessage) {
      onCloseMessage();
    }
    setTimeout(() => setResult(null), 333);
  };

  return (
    <form
      className={classNames(
        {
          "relative transition-fast": true,
          "opacity-50": loading,
        },
        className
      )}
      onSubmit={handleSubmit}
      name="Contact Form"
      data-netlify="true"
      data-netlify-honeypot="Information"
    >
      <RawText items={wrapper.heading.raw} className="text-green mb-4" />

      <RichText
        html={wrapper.description.html}
        className="text-green mb-6 md:mb-10"
      />

      {/* Honeypot */}
      <input
        name="Information"
        value={formData.Information}
        onChange={event =>
          setFormData({ ...formData, information: event.target.value })
        }
      />

      {/* Form-Builder */}
      <div className="flex flex-wrap -m-3">
        {form.map(({ id, element: Element, name, sliceType, ...props }) => (
          <div
            key={id}
            className={classNames({
              "w-full px-4 my-3": true,
              "md:w-1/2": sliceType !== "textarea",
              "mt-4": sliceType === "textarea",
            })}
          >
            <Text
              tag="label"
              options={{ style: "font-normal capitalize" }}
              className="block text-green-2 mb-1"
            >
              {name}
            </Text>
            <Element
              {...props}
              name={name}
              value={formData[name]}
              onChange={event =>
                setFormData({ ...formData, [name]: event.target.value })
              }
            />
          </div>
        ))}
      </div>

      {/* Submit */}
      <Button
        type="submit"
        preset="light"
        text={wrapper.ctaText}
        disabled={loading}
        className="mt-8"
      />

      {/* Success/Error Message */}
      <div
        className={classNames({
          "absolute inset-0 flex flex-col items-center justify-center p-6 md:-12 transition": true,
          "opacity-100 pointer-events-auto": showingMessage,
          "opacity-0 pointer-events-none": !showingMessage,
        })}
        style={{ backgroundColor: "rgba(255,255,255,.9)" }}
      >
        <Text tag="h3" className="mb-4 text-green-3">
          {result === "success"
            ? messages.successHeading
            : messages.errorHeading}
        </Text>

        <RichText
          html={
            result === "success"
              ? messages.successMessage.html
              : messages.errorMessage.html
          }
          className="text-green"
        />

        <Button
          preset="light"
          text="Close"
          className="mt-8 md:mt-12"
          onClick={closeMessage}
        />
      </div>
    </form>
  );
};

ContactForm.propTypes = {
  onCloseMessage: PropTypes.func,
  initialContact: PropTypes.string,
  className: PropTypes.string,
};

ContactForm.defaultProps = {
  onCloseMessage: null,
  initialContact: "",
  className: "",
};

export default ContactForm;
