import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 *
 * `Select` A mostly un-styled select with common handlers
 *
 */

const Select = ({
  name,
  value,
  options,
  arrow: Arrow,
  isRequired,
  onChange,
  onFocus,
  onBlur,
  disabled,
  focusedClassName,
  unFocusedClassName,
  className,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const select = useRef(null);

  const toggleFocus = () => {
    if (!isFocused && onFocus) {
      onFocus();
    } else if (isFocused && onBlur) {
      onBlur();
    }
    setIsFocused(!isFocused);
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  // We need to apply any padding classNames to the select itself, not the wrapper

  return (
    <div className="relative">
      <select
        className={classNames(
          {
            "Select w-full outline-none active:outline-none appearance-none transition": true,
            "cursor-pointer": !disabled,
            [unFocusedClassName]: !isFocused,
            [focusedClassName]: isFocused,
          },
          className
        )}
        ref={select}
        name={name}
        value={value}
        required={isRequired}
        onChange={handleChange}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        disabled={disabled}
      >
        {options.map(({ value: _value, title }) => (
          <option key={_value} value={_value}>
            {title}
          </option>
        ))}
      </select>

      <Arrow />
    </div>
  );
};

const DefaultArrow = () => (
  <svg
    width="22px"
    height="12px"
    viewBox="0 0 22 12"
    className="absolute pointer-events-none fill-coral"
    style={{ top: "50%", right: ".75rem", transform: "translateY(-50%)" }}
  >
    <polygon points="0, 0 22, 0 11, 12" />
  </svg>
);

export const selectPropTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  isRequired: PropTypes.bool,
  arrow: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  focusedClassName: PropTypes.string,
  unFocusedClassName: PropTypes.string,
  className: PropTypes.string,
};

Select.propTypes = selectPropTypes;

Select.defaultProps = {
  arrow: DefaultArrow,
  isRequired: true,
  onChange: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
  focusedClassName: "",
  unFocusedClassName: "",
  className: "",
};

export default Select;
