import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Pattern as PatternSVG, PatternAlt as PatternAltSVG } from "@svg";
import tl from "@timeline";

/**
 * `Pattern` animated SVG using the Pattern mark
 */

const Pattern = ({ variant, orientation, size, doAnimation, className }) => {
  const [hydrated, setHydrated] = useState(!doAnimation);
  const el = useRef();

  const SVG = variant === "default" ? PatternSVG : PatternAltSVG;

  useEffect(() => {
    if (el.current && doAnimation) {
      setHydrated(true);

      const selector = el.current.querySelectorAll("path");

      tl.fromTo(
        selector,
        1,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: {
            amount: 1,
            from: "center",
          },
        }
      );
    }
  }, []);

  if (!SVG) {
    return null;
  }

  const orientationClass =
    orientation.split("-")[1] === "left"
      ? "top-0 bottom-0 left-0"
      : "top-0 bottom-0 right-0";

  return (
    <div
      className={classNames({
        "Pattern absolute max-w-1/2 pointer-events-none opacity-50 lg:opacity-100": true,
        [`${orientationClass} ${className}`]: true,
        [`Pattern--${orientation}`]: variant === "default",
      })}
      style={{
        width: size === "lg" ? 400 : 200,
        marginTop:
          orientation === "top-left" || orientation === "top-right" ? -1 : 0,
      }}
    >
      <div
        ref={el}
        className="absolute inset-0"
        style={{ opacity: hydrated ? 1 : 0 }}
      >
        <SVG />
      </div>
    </div>
  );
};

Pattern.propTypes = {
  variant: PropTypes.string,
  orientation: PropTypes.oneOf([
    "top-left",
    "top-right",
    "bottom-left",
    "bottom-right",
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  doAnimation: PropTypes.bool,
  className: PropTypes.string,
};

Pattern.defaultProps = {
  variant: "default",
  orientation: "bottom-right",
  size: "lg",
  doAnimation: false,
  className: "",
};

export default React.memo(Pattern);
