// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-contact-page-contact-page-js": () => import("./../../../src/pageTemplates/ContactPage/ContactPage.js" /* webpackChunkName: "component---src-page-templates-contact-page-contact-page-js" */),
  "component---src-page-templates-home-page-home-page-js": () => import("./../../../src/pageTemplates/HomePage/HomePage.js" /* webpackChunkName: "component---src-page-templates-home-page-home-page-js" */),
  "component---src-page-templates-philosophy-page-philosophy-page-js": () => import("./../../../src/pageTemplates/PhilosophyPage/PhilosophyPage.js" /* webpackChunkName: "component---src-page-templates-philosophy-page-philosophy-page-js" */),
  "component---src-page-templates-portfolio-page-portfolio-page-js": () => import("./../../../src/pageTemplates/PortfolioPage/PortfolioPage.js" /* webpackChunkName: "component---src-page-templates-portfolio-page-portfolio-page-js" */),
  "component---src-page-templates-team-page-team-page-js": () => import("./../../../src/pageTemplates/TeamPage/TeamPage.js" /* webpackChunkName: "component---src-page-templates-team-page-team-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/Preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-prismic-dynamic-queries-general-pages-query-js": () => import("./../../../src/prismic/dynamicQueries/GeneralPagesQuery.js" /* webpackChunkName: "component---src-prismic-dynamic-queries-general-pages-query-js" */)
}

