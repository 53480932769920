import React, { useState, useEffect } from "react";

import { loadGTM } from "@utils";

const GTMLoader = () => {
  const [gtmLoaded, setGgtmLoaded] = useState(false);

  useEffect(() => {
    if (!gtmLoaded) {
      loadGTM();
      setGgtmLoaded(true);
    }
  }, []);

  return null;
};

GTMLoader.defaultProps = {};

GTMLoader.propTypes = {};

export default GTMLoader;
