import { useEffect } from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";

/**
 *
 * `ThemeSetter` Encapsulated theme toggler with access to global state
 *
 */

const ThemeSetter = ({ theme }) => {
  const [{ layout }, dispatch] = useAppState();

  useEffect(() => {
    if (layout.theme !== theme) {
      dispatch({ type: "setTheme", theme });
    }
  }, []);

  return null;
};

ThemeSetter.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default ThemeSetter;
