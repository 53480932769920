import React, { useState } from "react";
import PropTypes from "prop-types";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Container, Text } from "@atoms";
import { Link, Button } from "@ui";
import { useHeaderData } from "@staticQueries";
import { Logo } from "@svg";

const Header = ({ currentPath }) => {
  const [inView, setInView] = useState(true);
  const { menu, cta, offsite } = useHeaderData();

  const [{ layout }, dispatch] = useAppState();

  const handleMobileMenuToggle = () => {
    dispatch({ type: "toggleMobileNav" });
    // hide the announcment if showing
    if (layout.showingAnnouncement) {
      dispatch({
        type: "hideAnnouncement",
        announcementId: layout.showingAnnouncement,
      });
    }
  };

  const menuItemTextOptions = {
    font: "font-mono",
    size: "text-sm",
    lineHeight: "leading-1.2",
    letterSpacing: "tracking-md",
  };

  return (
    <InView
      onEnter={() => setInView(true)}
      onExit={() => setInView(false)}
      observerOptions={{
        threshold: 0.1,
      }}
    >
      <header
        className={classNames({
          "Header border-b border-green-3-40 transition relative z-50": true,
          "bg-green-1": layout.theme === "dark",
          "bg-gray-5": layout.theme === "light",
        })}
      >
        <Container
          className={classNames({
            "flex items-center py-4 transition-slow": true,
            "opacity-100": inView,
            "opacity-0": !inView,
          })}
        >
          <Link to="/" className={`Logo Logo--${layout.theme} relative z-50`}>
            <Logo />
          </Link>

          <nav className="hidden md:flex items-end ml-auto">
            <div className="flex flex-row items-center h-8">
              {menu.map((item, idx) => (
                <Link
                  key={idx}
                  to={item.link.url}
                  target={item.link.target}
                  className={classNames({
                    "mx-4 lg:mx-6 border-b-2 border-transparent hover:border-green-3 transition-fast": true,
                    "text-white": layout.theme === "dark",
                    "text-gray": layout.theme === "light",
                    "border-green-3":
                      currentPath !== "/" && currentPath === item.link.url,
                  })}
                >
                  <Text tag="span" options={menuItemTextOptions}>
                    {item.text}
                  </Text>
                </Link>
              ))}
            </div>

            <div className="flex flex-col items-center ml-4 lg:ml-6">
              <Link
                to={offsite.link.url}
                target={offsite.link.target}
                className={classNames({
                  "border-b-2 border-green-3 hover:border-green transition-fast mb-4": true,
                  "text-white": layout.theme === "dark",
                  "text-gray": layout.theme === "light",
                })}
              >
                <Text
                  tag="span"
                  options={{ ...menuItemTextOptions, size: "text-3xs" }}
                >
                  {offsite.text}
                </Text>
              </Link>

              <Link
                to={cta.link.url}
                target={cta.link.target}
                className="relative z-50"
              >
                <Button
                  preset="secondary"
                  text={cta.text}
                  options={{
                    height: "h-8",
                    text: layout.theme === "dark" ? "text-white" : "text-gray",
                  }}
                  textOptions={{ style: "font-normal" }}
                />
              </Link>
            </div>
          </nav>

          <Button
            preset="none"
            iconName={layout.showingMobileNav ? "X" : "Hamburger"}
            animateIcon={false}
            className={classNames({
              "relative md:hidden ml-auto z-50 h-10 w-10 text-xl -mr-2": true,
              "text-white": layout.theme === "dark",
              "text-gray": layout.theme === "light",
            })}
            onClick={handleMobileMenuToggle}
          />
        </Container>
      </header>
    </InView>
  );
};

Header.propTypes = {
  currentPath: PropTypes.string.isRequired,
};

export default Header;
