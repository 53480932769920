import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  resolveImage,
  resolveByPrefix,
  resolveExtraGroups,
  keysToCamel,
  resolveEmbeddedDocuments,
} from "@dataResolvers";

const query = graphql`
  query HomePageQuery {
    prismicHomePage {
      id
      data {
        hero {
          heading {
            raw
          }
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
        }
        testimonials_background_image {
          url
          alt
          dimensions {
            width
            height
          }
        }
        testimonials_list {
          quote {
            html
          }
          person
          title
          photo {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        portfolio_overview {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        portfolio_companies {
          company {
            document {
              ... on PrismicCompanies {
                id
                data {
                  name
                  description {
                    html
                  }
                  status
                  status_subtitle
                  exited
                  white_logo {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        philosophy_overview {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        team_overview {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        meta_title
        meta_description
        meta_image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicHomePage } = response;
  const { prefixed, unPrefixed } = resolveByPrefix(prismicHomePage.data, [
    "testimonials_",
    "portfolio_",
    "meta_",
  ]);
  const { testimonials, portfolio, meta } = prefixed;

  const unGrouped = resolveExtraGroups(unPrefixed);

  return keysToCamel({
    ...unGrouped,
    testimonials: {
      list: testimonials.list.map(item => ({
        ...item,
        photo: resolveImage(item.photo),
      })),
      backgroundImage: resolveImage(testimonials.background_image),
    },
    portfolio: {
      ...resolveExtraGroups({ overview: portfolio.overview }),
      companies: resolveEmbeddedDocuments(portfolio.companies).map(item => ({
        ...item,
        logo: resolveImage(item.white_logo),
      })),
    },
    meta: {
      ...meta,
      image: resolveImage(meta.image),
    },
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
