import React from "react";
import PropTypes from "prop-types";
import { resolveRawText } from "@dataResolvers";
import Text from "./Text";

/* eslint react/no-danger: 0 */

const RawText = ({ items, className }) => {
  return resolveRawText(items).map((item, idx) => (
    <Text key={idx} tag={item.tag} className={className}>
      <span dangerouslySetInnerHTML={{ __html: item.html }} />
    </Text>
  ));
};

export const rawTextProps = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    spans: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.number,
        end: PropTypes.number,
        type: PropTypes.string,
        data: PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.bool,
          ])
        ),
      })
    ).isRequired,
  }).isRequired
);

RawText.propTypes = {
  items: rawTextProps,
  className: PropTypes.string,
};

RawText.defaultProps = {
  items: [],
  className: null,
};

export default RawText;
