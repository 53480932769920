import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Text } from "@atoms";
import { inputPropTypes } from "./Input";
import Button from "../StyledButton";

/**
 *
 * `FileSelector` A styled file selector control
 *
 */

const FileSelector = ({ onChange, name, value, className }) => {
  const [filename, setFilename] = useState("");
  const input = useRef();

  const handleChange = () => {
    setFilename(input.current.value.split(/(\\|\/)/g).pop());
    // Form handler expects value to be passed as `event.target.value`;
    onChange({ target: { value: input.current.files[0] || "" } });
  };

  const clearFile = () => {
    input.current.value = "";
    handleChange("");
  };

  useEffect(() => {
    if (value === "") {
      clearFile();
    }
  }, [value]);

  return (
    <div className={`flex ${className}`}>
      <div className="flex-1 bg-gray-4 w-full h-10 flex items-center px-4 mr-2">
        <Text tag="span" className="text-gray text-sm">
          {filename}
        </Text>

        {filename && (
          <Button
            preset="none"
            iconName="X"
            animateIcon={false}
            className="ml-auto -mr-1 text-green hover:text-green-3"
            onClick={clearFile}
          />
        )}
      </div>

      <Button
        preset="secondary"
        text="Browse..."
        options={{
          height: "h-10",
          text: "text-green",
        }}
        textOptions={{ style: "font-normal" }}
        onClick={() => input.current.click()}
      />

      <input
        ref={input}
        type="file"
        name={name}
        className="absolute inset-0"
        onChange={handleChange}
        style={{ visibility: "hidden" }}
      />
    </div>
  );
};

FileSelector.propTypes = {
  ...inputPropTypes,
  name: inputPropTypes.name,
  value: inputPropTypes.value,
  className: PropTypes.string,
};

FileSelector.defaultProps = {
  name: "",
  value: "",
  className: "",
};

export default FileSelector;
