import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal as RawModal } from "@designbycosmic/cosmic-react-common-components";
import { Icon } from "@atoms";
import { fadeIn, fadeOut } from "@timeline";

const Modal = ({ closeButtonClassName, backgroundColor, ...rest }) => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => setHydrated(true));

  if (!hydrated) {
    return null;
  }

  return (
    <RawModal
      showingAnimation={fadeIn}
      hidingAnimation={fadeOut}
      className="z-60"
      style={{ backgroundColor, opacity: 0 }}
      ModalClose={({ onClick }) => (
        <button
          type="button"
          className={`absolute top-0 right-0 m-4 outline-none focus:outline-none transition-fast z-20 ${closeButtonClassName}`}
          onClick={onClick}
        >
          <Icon name="X" className="w-4 h-4" />
        </button>
      )}
      {...rest}
    />
  );
};

Modal.propTypes = {
  closeButtonClassName: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Modal.defaultProps = {
  closeButtonClassName: "text-green-2 hover:text-green-3",
  backgroundColor: "rgba(0,0,0,.5)",
};

export default Modal;
