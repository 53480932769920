import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  resolveImage,
  resolveByPrefix,
  resolveExtraGroups,
  resolveEmbeddedDocuments,
  keysToCamel,
} from "@dataResolvers";

const query = graphql`
  query TeamPageQuery {
    prismicTeamPage {
      id
      data {
        hero {
          heading {
            raw
          }
        }
        overview {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        team_heading {
          raw
        }
        team_subheading
        team_people {
          person {
            document {
              ... on PrismicPeople {
                id
                data {
                  name
                  title
                  photo {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  linkedin {
                    url
                    target
                  }
                  bio {
                    html
                  }
                }
              }
            }
          }
        }
        network_heading {
          raw
        }
        network_subheading
        network_people {
          person {
            document {
              ... on PrismicPeople {
                id
                data {
                  name
                  title
                  photo {
                    url
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                  linkedin {
                    url
                    target
                  }
                  bio {
                    html
                  }
                }
              }
            }
          }
        }
        testimonials {
          quote {
            html
          }
          person
          title
          photo {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        prefooter {
          heading {
            raw
          }
          subheading
          description {
            html
          }
          cta_text
          cta_link {
            url
            target
          }
          image {
            url
            alt
            dimensions {
              width
              height
            }
          }
        }
        meta_title
        meta_description
        meta_image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
`;

const dataResolver = response => {
  const { prismicTeamPage } = response;
  const { prefixed, unPrefixed } = resolveByPrefix(prismicTeamPage.data, [
    "team_",
    "network_",
    "meta_",
  ]);
  const { team, network, meta } = prefixed;

  const unGrouped = resolveExtraGroups(unPrefixed);

  return keysToCamel({
    ...unGrouped,
    team: {
      ...team,
      people: resolveEmbeddedDocuments(team.people).map(person => ({
        ...person,
        photo: {
          ...resolveImage(person.photo),
        },
      })),
    },
    network: {
      ...network,
      people: resolveEmbeddedDocuments(network.people).map(person => ({
        ...person,
        photo: {
          ...resolveImage(person.photo),
          filter: true,
        },
      })),
    },
    meta: {
      ...meta,
      image: resolveImage(meta.image),
    },
  });
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
