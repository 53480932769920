import { useEffect } from "react";
import { useAppState } from "@state/state";
import { useBreakpoints } from "@hooks";

// LayoutUpdater: this component returns nothing, and exists solely as a place to
// register hooks for updating

const hasWindow = typeof window !== "undefined";

const LayoutUpdater = () => {
  const [{ layout, breakpoints: _breakpoints }, dispatch] = useAppState();
  const breakpoints = hasWindow ? useBreakpoints() : _breakpoints;

  useEffect(() => {
    dispatch({
      type: "setBreakpoints",
      breakpoints,
    });
  }, Object.values(breakpoints));

  useEffect(() => {
    if (!layout.rendered) {
      dispatch({
        type: "setRendered",
        rendered: true,
      });
    }

    window.addEventListener("touchstart", function onFirstTouch() {
      dispatch({
        type: "setIsTouchDevice",
        isTouchDevice: true,
      });

      window.removeEventListener("touchstart", onFirstTouch, false);
    });
  }, []);

  return null;
};

export default LayoutUpdater;
