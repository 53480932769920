/* eslint-disable global-require */
module.exports = {
  theme: {
    fontSize: {
      "3xs": ".77rem", // 14px
      "2xs": ".833rem", // 15px
      xs: ".889rem", // 16px
      sm: ".94rem", // 17px
      base: "1rem", // 18px
      lg: "1.111rem", // 20px
      xl: "1.44rem", // 26px
      "1.5xl": "1.777rem", // 32px
      "2xl": "2.33rem", // 42px
      "3xl": "3.11rem", // 56px
      "4xl": "4rem", // 72 px
    },
    screens: {
      xxs: { max: "400px" },
      sm: "624px",
      md: "821px",
      mdLg: "1000px",
      lg: "1157px",
      xl: "1272px",
      xxl: "1432px",
    },
    fontFamily: {
      sans: ["Geograph", "sans-serif"],
      mono: ["Inconsolata", "monospace"],
    },
    maxWidth: theme => {
      return {
        none: "none",
        ...theme("screens"),
        "1/2": "50%",
        "1/3": "33.33%",
      };
    },
    boxShadow: {
      default: "0 11px 40px 0 rgba(1,34,46,0.35)",
      image: "0 8px 25px 0 rgba(10,41,50,0.15)",
      section: "0 2px 40px 0 rgba(13,41,24,0.3)",
    },
    extend: {
      margin: {
        "1px": "1px",
      },
      maxHeight: {
        64: "15rem",
        76: "18rem",
      },
      padding: {
        36: "9rem",
      },
      fill: theme => theme("colors"),
      opacity: {
        10: ".1",
        20: ".2",
        30: ".3",
        40: ".4",
        60: ".6",
        70: ".7",
        80: ".9",
        90: ".9",
      },
      zIndex: {
        60: "60",
      },
      letterSpacing: {
        none: "0px",
        sm: "0.45px",
        md: "0.5px",
        lg: "1px",
      },
      lineHeight: {
        "1": "1",
        "1.2": "1.2",
        "1.4": "1.4",
        "1.6": "1.6",
        "1.8": "1.8",
        "1.9": "1.9",
        "2": "2",
      },
      height: {
        7: "1.75rem",
      },
      minHeight: {
        "1/4-screen": "25vh",
        "1/3-screen": "33.333vh",
        "2/5-screen": "40vh",
        "3/7-screen": "43vh",
        "1/2-screen": "50vh",
        "4/7-screen": "57vh",
        "3/5-screen": "60vh",
        "2/3-screen": "66.666vh",
        "3/4-screen": "75vh",
      },
      colors: {
        green: {
          default: "#01514F",
          1: "#0E2424",
          2: "#01514F",
          3: "#00BC64",
          "3-40": "#00BC6464",
        },
        gray: {
          default: "#444E51",
          1: "#444E51",
          2: "#9AB0B7",
          3: "#C1CDD1",
          4: "#EDF1F2",
          5: "#F5F7F9",
        },
        white: "#FFFFFF",
      },
      inset: {
        4: "1rem",
        6: "1.5rem",
        20: "5rem",
      },
      translate: {
        1: ".5rem",
        8: "2rem",
        "-16": "-4rem",
        16: "4rem",
        100: "100%",
        "-100": "-100%",
      },
    },
  },
  variants: {
    opacity: ["hover", "group-hover", "responsive", "active"],
    translate: ["responsive", "group-hover"],
    textColor: ["hover", "group-hover", "responsive", "active"],
  },
  plugins: [
    require("tailwindcss-transition")({
      standard: "all .333s ease-in-out",
      transitions: {
        fast: "all .166s ease-in-out",
        slow: "all .666s ease-in-out",
        "transform-fast": "transform .166s ease-in-out",
      },
    }),
    require("tailwindcss-transforms")({
      "3d": false,
    }),
  ],
};
