import React from "react";
import PropTypes from "prop-types";
import Textarea, { textareaPropTypes } from "./Textarea";

/**
 *
 * `StyledTextarea` A styled component that wraps Textarea
 *
 */

const StyledTextarea = ({ className, ...rest }) => {
  return (
    <Textarea
      className={`StyledTextarea bg-gray-4 w-full border font-sans text-sm p-4 transition-fast rounded-none ${className}`}
      focusedClassName="border-green-3"
      unFocusedClassName="border-transparent"
      {...rest}
    />
  );
};

StyledTextarea.propTypes = {
  ...textareaPropTypes,
  className: PropTypes.string,
};

StyledTextarea.defaultProps = {
  className: "",
};

export default StyledTextarea;
