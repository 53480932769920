/**
 * Merge preview data and non-preview data (static data) so we can preview content, which
 * typically involves both preview and static data.
 *
 * `previewData` will be an object with a single key like `{ prismicHomePage: { id: abc, ...} }`
 *
 * `staticData` might have other stuff like `{ prismicHomePage: { id: abc, ...}, prismicGlobal: {...} }`
 *
 * The idea is that we loop through the keys of each, and if there's a matching key with a matching id,
 * then we replace that key in the staticData with the previewData
 *
 * We're only doing a top-level check to avoid performance issues that seemed inherent in the
 * `mergePrismicPreviewData` provided by the Prismic plugin. This means that the previewData will not
 * replace content buried inside an embedded document because those embedded documents aren't among
 * the top-level keys that we're inspecting.
 */

const hasWindow = typeof window !== "undefined";

const mergePreviewData = staticData => {
  const { __PRISMIC_PREVIEW_DATA__: previewData } = hasWindow ? window : {};

  // first make sure we have previewData that fits our routine, i.e., it's an object
  // and there's at least one key
  const previewDataIsValid =
    previewData &&
    typeof previewData === "object" &&
    Object.keys(previewData).length > 0;

  if (!previewDataIsValid) {
    return staticData;
  }

  // set up an array to capture any errors
  const errors = [];
  // get the first key of the previewData, which will be the contentType of the
  // document that is being previewed, e.g., `prismicHomePage`
  const previewKey = Object.keys(previewData)[0];

  try {
    // Get the id of the previewData document
    const { id: previewId } = previewData[previewKey] || {};

    // loop through the top-level of staticData to see if we have a matching key/id
    const match =
      previewId &&
      Object.entries(staticData).findIndex(
        ([key, node]) => key === previewKey && node.id === previewId
      ) > -1;

    // if there's a match, interpolate the previewData into the staticData
    if (match) {
      return {
        ...staticData,
        [previewKey]: previewData[previewKey],
      };
    }

    // if no match, then just return the staticData
    return staticData;
  } catch (error) {
    // set errors to the error array so we can notify users
    if (previewData) {
      errors.push(previewKey);
      previewData.errors = errors;
    }
    return staticData;
  }
};

export default mergePreviewData;
