/**
 * This is the place to configure the gsap timeline used for animations
 * throughout the site.
 */

import { gsap, TimelineLite } from "gsap";

const tl = new TimelineLite();

export const defaultDuration = 0.333;

// There may be a better way to do this but wanted to make it very easy
// to pass a variable number of args
// - first arg needs to be the element
// - if duration passed, needs to be second arg
// - if callback passed, needs to be last arg
const processFadeArgs = args => {
  const el = args[0];
  const duration =
    args[1] && typeof args[1] === "number" ? args[1] : defaultDuration;
  const options =
    typeof args[args.length - 1] === "object" ? args[args.length - 1] : {};

  return {
    el,
    duration,
    options,
  };
};

const fade = ({ el, to, duration, options }) => {
  const animator = options.useTimeLine ? tl : gsap;
  const { callback } = options;

  animator.to(el, duration, {
    opacity: to,
    onComplete: callback ? callback() : null,
  });
};

export const fadeIn = (...args) => {
  fade({ ...processFadeArgs(args), to: 1 });
};

export const fadeOut = (...args) => {
  fade({ ...processFadeArgs(args), to: 0 });
};

export default tl;
