// Map prismic text types to html tags
const tagMap = {
  heading1: "h1",
  heading2: "h2",
  heading3: "h3",
  heading4: "h4",
  paragraph: "p",
  hyperlink: "a",
  em: "em",
  strong: "strong",
};

// Map prismic attr types to html attrs
const attrMap = {
  url: "href",
  target: "target",
};

// Embedded hyperlinks have additional data that needs to be transformed
// into html attributes
const parseAttrs = data => {
  if (!data) {
    return "";
  }

  return Object.entries(data).reduce((attrs, [key, val]) => {
    const name = attrMap[key];
    if (!name) {
      return attrs;
    }

    return `${attrs} ${name}=${val}`;
  }, "");
};

// Interpolate html tags into text strings using start/end character indexes
const resolveItem = ({ type, text, spans }) => {
  if (text.length === 0 || spans.length === 0) {
    return {
      tag: tagMap[type],
      html: text,
    };
  }

  const arr = [...text];

  spans.forEach(({ start, end, type: _type, data }, idx) => {
    // multiply idx * 2 because for each span we're adding two additional
    // items to the array -- the opening and closing tags. Likewise, need
    // to add one more to the endIdx to account for the opening tag.
    const startIdx = start + idx * 2;
    const endIdx = end + idx * 2 + 1;

    const openingTag = `<${tagMap[_type]} ${parseAttrs(data)}>`;
    const closingTag = `</${tagMap[_type]}>`;

    arr.splice(startIdx, 0, openingTag);
    arr.splice(endIdx, 0, closingTag);
  });

  return {
    tag: tagMap[type],
    html: arr.join(""),
  };
};

const resolveRawText = (items = []) => {
  if (items.length === 0) {
    return items;
  }

  return items.map(resolveItem);
};

export default resolveRawText;
