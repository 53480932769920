import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { getTailwindUtility } from "@utils";

/**
 * `BreakPointer` renderProp component to apply screen-width breakpoint logic
 */

const screens = getTailwindUtility("screens");

const BreakPointer = ({ watch = [], render: Render }) => {
  const [{ breakpoints: _breakpoints }] = useAppState();

  const breakpoints = Object.keys(_breakpoints).reduce(
    (acc, cur) => {
      if (watch.includes(cur)) {
        return { ...acc, [cur]: _breakpoints[cur] };
      }

      return acc;
    },
    { current: _breakpoints.current }
  );

  if (!breakpoints.current) {
    return null;
  }

  return <Render {...breakpoints} />;
};

BreakPointer.propTypes = {
  render: PropTypes.func.isRequired,
  watch: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(screens))).isRequired,
};

export default BreakPointer;
