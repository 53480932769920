import { useStaticQuery, graphql } from "gatsby";
import { mergePrismicPreview, resolveImage, keysToCamel } from "@dataResolvers";

const query = graphql`
  query MetaDataQuery {
    prismicGlobals {
      id
      data {
        default_meta_description
        default_meta_image {
          url
          dimensions {
            height
            width
          }
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { default_meta_image, ...rest } = prismicGlobals.data;

  const resolved = {
    ...rest,
    defaultMetaImage: resolveImage(default_meta_image),
  };

  return keysToCamel(resolved);
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
