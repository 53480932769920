import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 *
 * `Input` A mostly un-styled input with handlers for common
 * input events.
 *
 */

const Input = ({
  type,
  placeholder,
  name,
  value,
  isRequired,
  autoComplete,
  size,
  onChange,
  onFocus,
  onBlur,
  className,
  focusedClassName,
  unFocusedClassName,
  style,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const toggleFocus = () => {
    if (!isFocused && onFocus) {
      onFocus();
    } else if (isFocused && onBlur) {
      onBlur();
    }
    setIsFocused(!isFocused);
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <input
      className={classNames(
        {
          "Input outline-none bg-transparent truncate": true,
          [unFocusedClassName]: !isFocused,
          [focusedClassName]: isFocused,
        },
        className
      )}
      style={style}
      type={type}
      name={name}
      title={name}
      value={value}
      placeholder={placeholder}
      required={isRequired}
      autoComplete={autoComplete}
      onChange={handleChange}
      onFocus={toggleFocus}
      onBlur={toggleFocus}
      size={size}
    />
  );
};

export const inputPropTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(Date),
      ])
    ),
  ]).isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  autoComplete: PropTypes.oneOf(["on", "off"]),
  size: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  focusedClassName: PropTypes.string,
  unFocusedClassName: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Input.propTypes = inputPropTypes;

Input.defaultProps = {
  type: "text",
  placeholder: "",
  isRequired: true,
  autoComplete: "on",
  size: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  className: "",
  focusedClassName: "",
  unFocusedClassName: "",
  style: {},
};

export default Input;
