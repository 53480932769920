import { useStaticQuery, graphql } from "gatsby";
import {
  mergePrismicPreview,
  keysToCamel,
  resolveByPrefix,
  resolveExtraGroups,
} from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    prismicGlobals {
      id
      data {
        header_menu {
          link {
            url
            target
          }
          text
        }
        header_cta {
          link {
            url
            target
          }
          text
        }
        header_offsite {
          link {
            url
            target
          }
          text
        }
      }
    }
  }
`;

const dataResolver = ({ prismicGlobals }) => {
  const { prefixed } = resolveByPrefix(prismicGlobals.data, ["header_"]);
  const { header } = prefixed;

  return keysToCamel(resolveExtraGroups(header));
};

const useData = () => {
  const response = useStaticQuery(query);
  const data = mergePrismicPreview(response);
  return dataResolver(data);
};

export default useData;
