import React from "react";
import PropTypes from "prop-types";
import * as svgs from "@svg";

const Icon = ({ name, className: _className, style }) => {
  const SVG = svgs[name] || name;

  return (
    <span
      className={`relative inline-block overflow-hidden ${_className}`}
      style={style}
    >
      <SVG />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Icon.defaultProps = {
  className: "w-4 h-4",
  style: {},
};

export default Icon;
