import React from "react";
import PropTypes from "prop-types";
import Select, { selectPropTypes } from "./Select";

// Select -- form select layout + change/blur handling

const StyledSelect = ({ className, ...rest }) => {
  return (
    <Select
      className={`StyledInput border text-gray bg-gray-4 w-full h-10 font-sans text-sm px-4 transition-fast rounded-none ${className}`}
      focusedClassName="border-green-3"
      unFocusedClassName="border-transparent"
      arrow={() => (
        <svg
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="absolute right-4 pointer-events-none text-green"
          style={{ top: "50%", transform: "translateY(-50%)" }}
        >
          <polyline points="6 9 12 15 18 9" />
        </svg>
      )}
      {...rest}
    />
  );
};

StyledSelect.propTypes = {
  ...selectPropTypes,
  className: PropTypes.string,
};

StyledSelect.defaultProps = {
  className: "",
};

export default StyledSelect;
