// import { toCamel } from "./keysToCamel";
import { Input, Select, Textarea } from "@ui";

/**
 * `resolveSlices` is used to transform an array of Prismic slices.
 * You will likely need to create a `case` for each slice that you define in Prismic.
 *
 * @param {object[]} body The array of slices
 *
 */

// Helper func to map slice_types to slice component names.
// Need to make camelCase and capitalized.
// const sliceTypeNameMap = sliceType => {
//   const sliceName = `${toCamel(sliceType)}Slice`;
//   return `${sliceName.charAt(0).toUpperCase()}${sliceName.slice(1)}`;
// };

// Helper func to get appropriate input type attrs
const inputTypeMap = {
  Text: "text",
  Email: "email",
  Phone: "tel",
  File: "file",
};

const resolveSlices = body => {
  const resolved = body
    .filter(block => block.primary || block.items)
    .map(block => {
      const { id, slice_type, primary, items } = block;

      switch (slice_type) {
        case "input":
          return {
            id,
            slice_type,
            element: Input,
            ...primary,
            name: primary.label,
            type: inputTypeMap[primary.type],
          };
        case "textarea":
          return {
            id,
            slice_type,
            ...primary,
            element: Textarea,
            name: primary.label,
          };
        case "person_selector":
          return {
            id,
            slice_type,
            ...primary,
            element: Select,
            name: primary.label,
            options: [
              {
                title: "Select Contact",
                value: "",
              },
              ...items.map(({ person }) => ({
                title: person.document.data.name,
                value: person.document.data.name,
              })),
            ],
          };
        case "rich_text":
          return {
            id,
            slice_type,
            ...primary,
          };

        default:
          return null;
      }
    });

  return resolved;
};

export default resolveSlices;
