import React from "react";
import PropTypes from "prop-types";
import { UnWidowerReactChildren } from "@designbycosmic/cosmic-unwidower";

/**
 * Renders HTML text content with predefined Tailwind classNames.
 * To use in a project, you should wrap `Text` in a decorator component
 */

const TextRaw = ({
  tag: Tag,
  use,
  options,
  className: _className,
  style,
  children,
  preserveWidows,
  config,
}) => {
  if (!Tag || !config || typeof config !== "object") {
    return null;
  }

  const { typography = {}, unwidower = {} } = config;

  // Apply the UnWidower to the children text content unless
  // `preserveWidows` was passed
  const Content = () => {
    if (!preserveWidows) {
      return (
        <UnWidowerReactChildren config={unwidower}>
          {children}
        </UnWidowerReactChildren>
      );
    }
    return children;
  };

  // Merge the default className object for the passed Tag
  // with any options also passed
  const classNameObject = {
    ...(typography[use || Tag] || {}),
    ...options,
  };

  // Process the object into a single className string
  const className = Object.values(classNameObject).reduce(
    (str, cur) => `${str} ${cur}`,
    _className
  );

  return (
    <Tag className={className} style={style}>
      <Content />
    </Tag>
  );
};

export const TextRawProps = {
  config: PropTypes.shape({
    typography: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.objectOf(PropTypes.string),
      ])
    ).isRequired,
    unwidower: PropTypes.objectOf(PropTypes.number),
  }).isRequired,
  tag: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.string),
  use: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  preserveWidows: PropTypes.bool,
};

TextRaw.propTypes = TextRawProps;

TextRaw.defaultProps = {
  options: {},
  use: undefined,
  className: "",
  style: {},
  preserveWidows: false,
};

export default TextRaw;
