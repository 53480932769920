import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import { useMetaData } from "@staticQueries";
import { imageProps } from "@atoms";

const SEO = ({ title: _title, description: _description, image: _image }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `
  );

  const { author } = site.siteMetadata;

  const { defaultMetaDescription, defaultMetaImage } = useMetaData();

  // use passed metadata and fallback to site defaults
  const title = `${_title} | ${author}`;
  const description = _description || defaultMetaDescription;
  const image = _image || defaultMetaImage;

  // build imgix url for meta image
  const { url, filter } = image;
  let imageSrc = `${url}&w=1200&h=630`;

  if (filter) {
    imageSrc += `&monochrome=${filter}`;
  }

  return (
    <Helmet
      title={title}
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1 maximum-scale=1",
        },
        {
          name: "description",
          content: description,
        },
        {
          property: "og:image",
          content: imageSrc,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: author,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: description,
        },
      ]}
    />
  );
};

export const seoProps = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: imageProps,
};

SEO.propTypes = seoProps;

SEO.defaultProps = {
  description: "",
  image: null,
};

export default SEO;
