const reducer = (state, action) => {
  switch (action.type) {
    case "setCurrentSlide":
      return {
        ...state,
        currentSlide: action.currentSlide,
      };
    default:
      return state;
  }
};

const initialState = {
  currentSlide: 0,
};

export default {
  reducer,
  initialState,
};
