import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "@designbycosmic/cosmic-react-common-components";
import { Text, Icon } from "@atoms";

/**
 *
 * `StyledButton` A styled component that wraps the Cosmic Button
 *
 */

const presets = {
  light: {
    bg: "bg-green-3",
    border: "rounded-full",
    text: "text-white hover:text-green-1",
    height: "h-10",
    padding: "px-6",
    iconName: "Arrow",
  },
  dark: {
    bg: "bg-green-3",
    border: "rounded-full",
    text: "text-green-1 hover:text-white",
    height: "h-10",
    padding: "px-6",
    iconName: "Arrow",
  },
  secondary: {
    bg: "bg-transparent hover:bg-green-3",
    border: "rounded-full border border-green-3",
    text: "text-white",
    height: "h-10",
    padding: "px-6",
  },
};

const StyledButton = ({
  text,
  iconName: _iconName,
  animateIcon,
  preset: _preset,
  options,
  textOptions,
  className: _className,
  ...rest
}) => {
  const preset = presets[_preset] || {};

  // Merge the default className object for the passed Tag
  // with any options also passed
  const classNameObject = {
    ...preset,
    ...options,
  };

  // Process the object into a single className string
  const className = Object.values(classNameObject).reduce(
    (str, cur) => `${str} ${cur}`,
    _className
  );

  const iconName = _iconName || preset.iconName;

  return (
    <Button
      className={classNames(
        {
          "StyleButton outline-none focus:outline-none transition-fast": true,
          "group flex items-center": !!iconName,
          "justify-center": !!iconName && !animateIcon,
        },
        className
      )}
      {...rest}
    >
      {text && (
        <Text
          tag="span"
          use="button"
          options={textOptions}
          className="block -mt-1px"
        >
          {text}
        </Text>
      )}

      {iconName && (
        <Icon
          name={iconName}
          className={classNames({
            "w-4 h-4": true,
            "group-hover:translate-x-1 transition-transform-fast ml-2": animateIcon,
          })}
        />
      )}
    </Button>
  );
};

StyledButton.propTypes = {
  text: PropTypes.string,
  iconName: PropTypes.string,
  animateIcon: PropTypes.bool,
  preset: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.string),
  textOptions: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

StyledButton.defaultProps = {
  text: undefined,
  iconName: undefined,
  animateIcon: true,
  preset: "light",
  options: {},
  textOptions: {},
  className: "",
};

export default StyledButton;
