const parseImageData = (props, idx) => {
  if (!props || typeof props !== "object") {
    return null;
  }

  const { image: _image, focal_point_x, focal_point_y, caption } = props;

  // Image can be passed directly, or bundled with additional props
  const image = _image || props;
  const { url, alt, dimensions } = image;

  if (!url) {
    return null;
  }

  const parsedImage = {
    id: idx || 0,
    url,
    alt,
    width: dimensions.width,
    height: dimensions.height,
    fpx: focal_point_x,
    fpy: focal_point_y,
    caption,
  };

  return parsedImage;
};

const resolveImageData = data => {
  if (Array.isArray(data)) {
    return data.map(parseImageData);
  }
  return parseImageData(data);
};

export default resolveImageData;
