import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * `RichText` - render wysiwyg copy.
 */

// Disable warning about `dangerouslySetInnerHTML`, which we need to
// use to render the html returned from the wysiwyg.
/* eslint react/no-danger: 0 */

const RichText = ({ html, className: _className }) => {
  const className = classNames(
    {
      RichText: true,
      "list-reset m-0 p-0": true,
    },
    _className
  );

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  );
};

RichText.propTypes = {
  html: PropTypes.string.isRequired,
  className: PropTypes.string,
};

RichText.defaultProps = {
  className: "",
};

export default RichText;
