import React, { useRef } from "react";
import PropTypes from "prop-types";
import { InView } from "@designbycosmic/cosmic-react-common-components";
import { TimelineLite } from "gsap";

/**
 *
 * `StaggerIn` animates child elements in a staggered sequence
 *
 */

const StaggerIn = ({ children, className }) => {
  const el = useRef();
  const tl = new TimelineLite();

  const onEnter = () => {
    if (el.current) {
      const selector = el.current.querySelectorAll(".StaggerIn__child");
      tl.fromTo(
        selector,
        0.75,
        {
          opacity: 0,
          y: 18,
        },
        {
          opacity: 1,
          y: 0,
          stagger: {
            amount: (1 / 12) * children.length,
          },
          onComplete: () => {
            // make elements have transform:none because we allow embedded modals
            // inside children, which are position:fixed, and these two styles
            // don't play nice:
            // https://stackoverflow.com/questions/2637058/positions-fixed-doesnt-work-when-using-webkit-transform
            for (let i = 0; i < selector.length; i += 1) {
              selector[i].style.transform = "none";
            }
          },
        }
      );
    }
  };

  const items = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
      className: `StaggerIn__child ${child.props.className} opacity-0`,
    })
  );

  return (
    <div ref={el} className="StaggerIn">
      <InView
        onEnter={onEnter}
        observerOptions={{
          threshold: 0.01,
        }}
        unobserveAfterEntry
        className={className}
      >
        {items}
      </InView>
    </div>
  );
};

StaggerIn.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

StaggerIn.defaultProps = {
  className: "",
};

export default React.memo(StaggerIn);
