import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 *
 * `Textarea` A mostly un-styled textarea with handlers for common
 * events.
 *
 */

const Textarea = ({
  placeholder,
  name,
  value,
  isRequired,
  autoComplete,
  rows,
  onChange,
  onFocus,
  onBlur,
  className,
  focusedClassName,
  unFocusedClassName,
  style,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const toggleFocus = () => {
    if (!isFocused && onFocus) {
      onFocus();
    } else if (isFocused && onBlur) {
      onBlur();
    }
    setIsFocused(!isFocused);
  };

  const handleChange = event => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <textarea
      className={classNames(
        {
          "Textarea outline-none bg-transparent": true,
          [unFocusedClassName]: !isFocused,
          [focusedClassName]: isFocused,
        },
        className
      )}
      style={style}
      name={name}
      title={name}
      value={value}
      rows={rows}
      placeholder={placeholder}
      required={isRequired}
      autoComplete={autoComplete}
      onChange={handleChange}
      onFocus={toggleFocus}
      onBlur={toggleFocus}
    />
  );
};

export const textareaPropTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  autoComplete: PropTypes.oneOf(["on", "off"]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  focusedClassName: PropTypes.string,
  unFocusedClassName: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Textarea.propTypes = textareaPropTypes;

Textarea.defaultProps = {
  placeholder: "",
  rows: 6,
  isRequired: true,
  autoComplete: "on",
  onChange: null,
  onFocus: null,
  onBlur: null,
  className: "",
  focusedClassName: "",
  unFocusedClassName: "",
  style: {},
};

export default Textarea;
